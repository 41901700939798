.product-preview {
  display: flex;
  flex-direction: column;
  height: 350px;
  width: 22%;
  .product-preview__image {
    width: 100%;
    height: 90%;
    background-size: cover;
    background-position: center;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    button {
      // position: relative;
      // width: 80%;
      // height: fit-content;
      // margin-bottom: 15px;
      // padding: 20px 15px;
      // border: none;
      // opacity: 0.85;
      display: none;
      // font-size: larger;
      // text-transform: uppercase;
    }
    &:hover {
      // filter: brightness(150%);
      opacity: 0.85;
      button {
        display: block;
        cursor: pointer;
        opacity: 1;
      }
    }
  }
  .product-preview__info {
    height: 10%;
    display: flex;
    justify-content: space-between;
    font-size: larger;
  }
}
