.sign-in {
  width: 30vw;
  display: flex;
  flex-direction: column;
  .title {
    margin: 10px 0;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .button {
      margin: 5px 0;
    }
  }
}
