.cart-item {
  width: 100%;
  display: flex;
  height: 80px;
  margin-bottom: 15px;
}
.cart-item img {
  width: 30%;
}
.cart-item .item-details {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 20px;
}
.cart-item .item-details .name {
  font-size: 16px;
}/*# sourceMappingURL=cart-item.style.css.map */