.cart-dropdown {
  position: absolute;
  width: 280px;
  height: 340px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid black;
  background-color: white;
  top: 90px;
  right: 40px;
  z-index: 5;
}
.cart-dropdown .cart-items {
  height: 240px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.cart-dropdown .cart-items::-webkit-scrollbar {
  width: 0.5em;
}
.cart-dropdown .empty-message {
  font-size: 18px;
  margin: auto;
  font-weight: bold;
}
.cart-dropdown button {
  margin-top: auto;
}/*# sourceMappingURL=cart-dropdown.style.css.map */