.collection {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  padding: 20px 80px;
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  a {
    text-decoration: none;
    color: #000;
    font-size: larger;
    font-weight: bold;
    border-bottom: #000 1px solid;
    transition: all 0.1s ease-in-out;
    &:hover {
      color: #074a6c;
      border: none;
    }
  }
  .title {
    text-align: center;
    margin: auto;
    font-size: 28px;
  }

  .preview {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
}
