button {
  width: 225px;
  margin-bottom: 15px;
  padding: 10px 15px;
  border: none;
  opacity: 0.85;
  background-color: #000;
  color: #fff;
  font-size: large;
  text-transform: uppercase;
}
button:hover {
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  cursor: pointer;
}

.google-sign-in {
  background-color: #4285f4;
  border: 1px solid #4285f4;
}
.google-sign-in:hover {
  background-color: #fff;
  color: #4285f4;
}

.inverted {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.inverted:hover {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
}/*# sourceMappingURL=custom-button.style.css.map */