.checkout-item {
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 20px;
  align-items: center;

  .image-container {
    width: 23%;
    padding-right: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .name,
  .quantity,
  .price {
    width: 23%;
  }

  .quantity {
    display: flex;
    justify-items: center;
    align-items: center;
    padding-left: 20px;
    .value {
      margin: 0 10px;
    }
  }
  .arrow {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: 2px solid #6c757d;
    border-radius: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #6c757d;
      color: #34b3f1;
    }
  }

  .remove-button {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: 2px solid #6c757d;
    border-radius: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    &:hover {
      background-color: #6c757d;
      color: #d61c4e;
    }
  }
}
