.cart-dropdown {
  position: absolute;
  width: 280px;
  height: 340px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid black;
  background-color: white;
  top: 90px;
  right: 40px;
  z-index: 5;
  .cart-items {
    height: 240px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 0.5em;
    }
  }
  .empty-message {
    font-size: 18px;
    margin: auto;
    font-weight: bold;
  }
  button {
    margin-top: auto;
  }
}
