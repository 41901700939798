.menu-item {
  min-width: 30%;
  height: 240px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  margin: 0 7.5px 15px;
  overflow: hidden;
}
.menu-item .background-image {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.menu-item.large {
  height: 380px;
}
.menu-item:hover {
  cursor: pointer;
}
.menu-item:hover .background-image {
  transform: scale(1.1);
  transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
}
.menu-item:hover .content {
  opacity: 0.9;
}
.menu-item:first-child {
  margin-right: 7.5px;
}
.menu-item:last-child {
  margin-left: 7.5px;
}
.menu-item .content {
  height: 90px;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  background-color: white;
  opacity: 0.7;
  position: absolute;
}
.menu-item .content .title {
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 22px;
  color: #4a4a4a;
  text-transform: uppercase;
}
.menu-item .content .subtitle {
  font-weight: lighter;
  font-size: 16px;
}/*# sourceMappingURL=menu-items.style.css.map */