.product-preview {
  display: flex;
  flex-direction: column;
  height: 350px;
  width: 22%;
}
.product-preview .product-preview__image {
  width: 100%;
  height: 90%;
  background-size: cover;
  background-position: center;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.product-preview .product-preview__image button {
  display: none;
}
.product-preview .product-preview__image:hover {
  opacity: 0.85;
}
.product-preview .product-preview__image:hover button {
  display: block;
  cursor: pointer;
  opacity: 1;
}
.product-preview .product-preview__info {
  height: 10%;
  display: flex;
  justify-content: space-between;
  font-size: larger;
}/*# sourceMappingURL=product-preview.style.css.map */