.checkout-page {
  width: 55%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 0;
}
.checkout-page .checkout-header {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid darkgrey;
}
.checkout-page .checkout-header .header-block {
  text-transform: capitalize;
  width: 23%;
}
.checkout-page .checkout-header .header-block:last-child {
  width: 8%;
}
.checkout-page .test-warning {
  text-align: center;
  margin-top: 40px;
  font-size: 24px;
  color: red;
}
.checkout-page .total {
  margin-top: 30px;
  margin-left: auto;
  font-size: 36px;
}/*# sourceMappingURL=checkout.style.css.map */