button {
  width: 225px;
  margin-bottom: 15px;
  padding: 10px 15px;
  border: none;
  opacity: 0.85;
  //   display: none;
  background-color: #000;
  color: #fff;
  font-size: large;
  text-transform: uppercase;
  &:hover {
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    cursor: pointer;
  }
}
.google-sign-in {
  background-color: #4285f4;
  border: 1px solid #4285f4;
  &:hover {
    background-color: #fff;
    color: #4285f4;
  }
}
.inverted {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  &:hover {
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
  }
}
