.header {
  display: flex;
  justify-content: space-between;
  padding: 20px 80px;
  background-color: #f1efef;
  a {
    color: #000;
    text-decoration: none;
  }
  .logo-text {
    font-size: 32px;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
  }
  .nav-links {
    width: 80%;
    display: flex;
    justify-content: right;
    .links {
      color: #000;
      text-decoration: none;
      font-size: larger;
      padding: 5px 20px;
      margin: auto 10px;
      text-transform: uppercase;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
