.cart-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  .shopping-cart {
    width: 20px;
    height: 20px;
    fill: #fff;
  }
  .item-count {
    position: absolute;
    font-size: 14px;
    font-weight: bold;
    bottom: 5px;
    border-radius: 50%;
  }
}
