.sign-in {
  width: 30vw;
  display: flex;
  flex-direction: column;
}
.sign-in .title {
  margin: 10px 0;
}
.sign-in .buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sign-in .buttons .button {
  margin: 5px 0;
}/*# sourceMappingURL=sign-in.style.css.map */